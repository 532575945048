import React from "react"
import { Icon } from "semantic-ui-react"

import "./style.less"

export default function Header({ top, active }) {
  return (
    <div style={{ top: (top > 0 ? top : 0) + 'px' }} className="main-nav" >
      <div className="nav-frame">
        <div className="frame-pieces">
          <div className="top frame-piece"></div>
          <div className="bottom frame-piece"></div>
          <div className="left frame-piece"></div>
          <div className="right frame-piece"></div>
        </div>
      </div>
      <ul className="nav-items">
        <li className={"nav-item" + (active === "home" ? " active" : "")}>
          <a href="/" className="nav-link">
            Home
          </a>
        </li>
        <li className={"nav-item" + (active === "get-started" ? " active" : "")}>
          <a href="/get-started" className="nav-link">
            Get started
          </a>
        </li>
        <li className={"nav-item" + (active === "about" ? " active" : "")}>
          <a href="/about" className="nav-link">
            About
          </a>
        </li>
        <li className={"nav-item" + (active === "faq" ? " active" : "")}>
          <a href="/faq" className="nav-link">
            FAQ
          </a>
        </li>
        <li className={"nav-item" + (active === "support" ? " active" : "")}>
          <a href="/support" className="nav-link">
            Support
          </a>
        </li>
        <li className="nav-item hide-mobile">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://armory.slashdiablo.net/"
            className="nav-link"
          >
            Armory <Icon size="small" name="external alternate" />
          </a>
        </li>
        <li className="nav-item hide-mobile">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://ladder.slashdiablo.net/exphardcore/overall/"
            className="nav-link"
          >
            Ladder <Icon size="small" name="external alternate" />
          </a>
        </li>
      </ul>
    </div >
  )
}
