import React from "react"
import { Container, List } from "semantic-ui-react"

import "./style.less"

const Footer = () => (
  <Container className="footer">
    <List celled horizontal>
      <List.Item>
        <a href="/about">About us</a>
      </List.Item>
      <List.Item>
        <a href="/support">Support</a>
      </List.Item>
      <List.Item>
        <a href="/faq">FAQ</a>
      </List.Item>
    </List>
    <p>&copy; {new Date().getFullYear()} SlashDiablo</p>
  </Container>
)

export default Footer
